@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,700&display=swap');
body {
    font-family: 'Roboto', sans-serif;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
}
body.authBody {
    // background-color: #EDF6F8;
    background-color: #fff;
}
a, a:visited, .btn-link {
    color: #1B609D;
    text-decoration: none;
    &:hover {
        color: #000;
    }
}
.page404 {
    max-width: 60%;
}
#root {
    position: relative;
}
.disabled-item {
    opacity: 0.4 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
}

/**** Button / Link Start ****/
.btn-primary, .btn-primary:active, .btn-primary:visited {
    background-color: #1B609D;
    border-color: #1B609D;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    color: #fff;
}
.btn-primary:hover, .btn-primary:visited:hover, .btn-primary:active:hover .btn-primary:focus {
    background-color: #247bc7;
    border-color: #247bc7;
    color: #fff;
}
.btn-secondary, .btn-secondary:active, .btn-secondary:visited {
    background: #fff;
    border-color: #ddd;
    color: #000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
.btn-secondary:hover, .btn-secondary:focus {
    border-color: #000;
}
.btn-outline-primary {
    border-color: #1B609D;
    color: #1B609D;
}
.btn-outline-primary:hover {
    background-color: #1B609D;
    color: #fff;
}
.action-icons-box {
    .action-icons {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
.action-icons {
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.20);
    border-radius: 5px;
    display: inline-block;
    padding: 5px 6px;
    text-align: center;
    text-wrap: nowrap;
    img {
        width: 24px;
    }
    &:hover {
        background-color: rgba(27, 96, 157, 0.05);
    }
    &.small-icon {
        padding: 5px 10px;
        img {
            width: auto;
            vertical-align: initial;
            margin-right: 0.25rem;
        }
    }
    &.disabled {
        opacity: 0.25;
        cursor: default;
    }
}
.smallText {
    font-size: 14px;
}
.smallInstruction {
    font-size: x-small;
    font-weight: 400;
    color: #444;
    margin: 10px 0px;
}
.btn:disabled{
    background: #666666;
    border-color: #666666;
}
.statusBadges {
    border-radius: 1rem;
    text-align: center;
    padding: 2px 20px;
    display: inline-block;
    &.pending, &.not_started {
        color: #fa7314;
        background-color: #ffebcc;
    }
    &.approved, &.active, &.in_progress {
        color: #008000;
        background-color: #c2f4c2;
    }
    &.rejected, &.inactive, &.terminated, &.invalid {
        color: #ff0000;
        background-color: #f7cdcd;
    }
    &.completed, &.closed {
        color: #222; 
        background-color: #e2e6ea;
    }
}
/**** Button End ****/


/**** Form Start ****/
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #929DA7;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #929DA7;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: #929DA7;
}
.error-message {
    color: #ff0000;
    font-size: 11px;
    margin-bottom: 0.25rem;
}
.form-control {
    padding: 0.5rem 0.75rem;
}
label {
    font-weight: 500;
    // font-size: 14px;
}
.badge {
    border-radius: 0.75rem;
}
.form-check-input, input[type="checkbox"] {
    // margin-top: 0;
    margin-right: 0.25rem;
    box-shadow: 2.5px 2.5px 5px 0px #CFD0D2 inset, -2.5px -2.5px 5px 0px #EBEBEB inset;
    &:checked {
        background-color: #0A5599;
        border-color: #0A5599;
        box-shadow: none;
    }
}
input[type="text"], input[type="password"], .form-control {
    outline: 0;
    border-radius: 30px;
    padding: 7px 12px;
    border-radius: 6px;
    border: 0.5px solid rgba(255, 255, 255, 0.80);
    background: #FFF;
    box-shadow: 2.5px 2.5px 5px 0px #CFD0D2 inset, -2.5px -2.5px 5px 0px #EBEBEB inset;
}
input[type="text"]:focus, input[type="password"]:focus, .form-control:focus {
    box-shadow: 2.5px 2.5px 5px 0px #CFD0D2 inset, -2.5px -2.5px 5px 0px #EBEBEB inset;
}
.form-select {
    border-radius: 6px;
    border: 0.5px solid rgba(255, 255, 255, 0.80);
    box-shadow: 2.5px 2.5px 5px 0px #CFD0D2 inset, -2.5px -2.5px 5px 0px #EBEBEB inset;
}
.program-filter {
    select {
        width:160px;
    }
}
.multiSelectContainer {
    input {
        background: #fff;
    }
}
/**** Form End ****/


/**** Progress Start ****/
.progress {
    --bs-progress-height: 0.4rem;
    --bs-progress-bg: #d9d9d9;
}
.progress-bar {
    background: linear-gradient(269.92deg, #0F4C81 1.69%, #1270C4 99.93%);
}
/**** Progress End ****/

/**** Grid Start ****/
.container-fluid {
    max-width: 98%;
}
.container-fluid .container-fluid {
    max-width: 100%;
}
/**** Grid End ****/

/**** Table Start ****/
.table  {
    --bs-table-striped-bg: rgba(36, 117, 160, 0.05);
    --bs-table-hover-bg: rgba(36, 117, 160, 0.05);
    border: 1px solid #0D70E8;
    background-image: url('../images/table-bg.svg');
    background-position: left top;
    background-repeat: repeat;
    thead tr {
            background-color: #0F80EF;
            background-image: url('../images/table-head-bg.svg');
            background-repeat: no-repeat;
            background-size: cover;
    }
    th {
        font-weight: 500;
        color: #fff;
        text-shadow: 0px -2px 5px rgba(0, 0, 0, 0.25), 0px 2px 5px rgba(0, 0, 0, 0.25);
        vertical-align: middle;
        background-color: transparent;
        > div span {
            display: flex;
            cursor: pointer;
            user-select: none;
            width: max-content;
            align-items: center;
            span{
                padding-left: 5px;
            }
        }
    }
    td {
        vertical-align: middle;
    }
}
.admin-table-wrapper {
    th:last-child {
        width: 14%;
        text-align: center;
    }
    td:last-child {
        text-align: center;
    }
}
.table-hover>tbody>tr:hover>* {
    background: linear-gradient(0deg, rgba(36, 117, 160, 0.05) 0%, rgba(27, 96, 157, 0.05) 52.08%);
}
/**** Table End ****/

/**** Modal Start ****/
.modal {
    --bs-modal-header-padding: 0.75rem 1.5rem;
    --bs-modal-bg: #E8EFF5;
    --bs-modal-header-padding-y: 0.5rem;
    --bs-modal-header-padding-x: 0.5rem;
    --bs-modal-padding: 1rem 1.5rem;
    --bs-modal-footer-gap: 1rem;
}
.modal-buttons {
    text-align: center;
    padding-top: 0.5rem;
    button {
        margin-right: 0.25rem;
        &:last-child {
            margin-right: 0;
        }
    }
}
.modal-title {
    font-size: 1.25rem;
}

//
.modal-design-wrapper {
    .modal-body{
        padding-top: 0;
        padding-bottom: 2rem;
        padding-top: 1rem;
        z-index: 2;
    }
    .modal-content {
    //   background: #f3f6fa url(../../../assets/images/landing-bg.png);
        background: white url(../../assets/images/landing-bg.png);
        border: none;
    }
    .wavebg{
        width: 55%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
/**** Modal End ****/

/**** Alert Start ****/
.alert {
    --bs-alert-padding-x: 0.75rem;
    --bs-alert-padding-y: 0.75rem;
}
/**** Alert End ****/

/**** Custom Scrollbar Start ****/
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/**** Custom Scrollbar End ****/

/**** Tab Start ****/
.tabStep-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #E8ECF1;
    box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    .step, a {
        flex: 1;
        padding: 10px;
        text-align: center;
        color: #7D7D7D;
        font-weight: 500;
    }
    .step.active {
        border: 2px solid #0E74EA;
        color: #000;
        border-radius: 50px;
    }
}
.tabStep-content {
    margin-bottom: 20px;
}
.tabStep-footer {
    display: flex;
    justify-content: center;
}
.nav-tabs {
    margin-bottom: 20px;
    justify-content: space-between;
    background: #E8ECF1;
}
/**** Tab End ****/

/**** Block Start ****/
.mitblock {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    padding: 1.25rem;
}
.mitblock-title {
    font-size: 18px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
}
.mitblock-body {
    margin-top: 1.5rem;
    .container-fluid {
        max-width: 100%;
    }
}
/**** Block End ****/

/**** Headings Start ****/
.page-title {
    border-bottom: 1px solid #ddd;
}
/**** Headings End ****/

/**** Filter Start ****/
.filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(27, 96, 157, 0.05);
    padding: 0.75rem;
}
.top-filter{
    flex-direction: row-reverse;
}
.site-button-group {
    button {
        margin-right: 0.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
}
/**** Filter End ****/

/**** Component Heading with Filter Start ****/
.mitcomponet-heading {
    margin-bottom: 30px;
    h3 {
        margin-bottom: 0;
    }
}
/**** Component Heading with Filter End ****/

.select-institute, .switch-role {
    display: flex;
    margin-right: 10px;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
    }
    .head-icon {
        border: 0.5px solid #E7E7E7;
        background-color: #F4F7FF;
        border-radius: 50%;
        width: 46px;
        height: 46px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        padding: 0;
    }
}
.popover-header {
    background-color: #0F80EF;
    background-image: url('../images/table-head-bg.svg');
    background-repeat: repeat;
    color: #fff;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    --bs-popover-bg: #0F80EF;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: #0F80EF;
}

// -------------- Background Waves Start -------------
.left-cicle {
    position: absolute;
    left: 0;
    z-index: -1;
    bottom: 22rem;
    height: 17rem;
}
.bottom-bg {
    display: flex;
    justify-content: flex-end;
    height: max-content;
    position: absolute;
    z-index: -1;
    bottom: 2rem;
    right: 0;
    // margin-top: -13rem;
}
.bottomLeftWave {
    display: flex;
    height: max-content;
    position: absolute;
    z-index: -1;
    bottom: 2rem;
    left: 0;
    // margin-top: -17rem;
    img {
        width: 100%;
    }
}
// -------------- Background Waves End -------------