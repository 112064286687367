.site-header {
    --bs-gutter-x: 1.5rem;
    padding: 10px calc(var(--bs-gutter-x) * .5); border-bottom: 1px solid #E7E7E7;
    min-height: 90px;
    .site-logo {
        margin-left: 0.75rem;
        img {
            max-height: 68px;
        }
    }
    .sh-toolbar {
        li {
            border: 0.5px solid #E7E7E7;
            background-color: #F4F7FF;
            border-radius: 50%;
            width: 46px;
            height: 46px;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
        }
        .sh-notification {
            position: relative;
            span {
                background: linear-gradient(180deg, #28C8E5 0%, #2C2C33 100%);
                position: absolute;
                top: -2px;
                right: 0;
                padding: 1px 5px;
                border-radius: 50%;
                font-size: 10px;
                color: #fff;
            }
        }
        img {
            width: 22px;
        }
    }
    .user-profile-pix {
        width: 46px;
        height: 46px;
        background-color: #F4F7FF;
        border: 0.5px solid #E7E7E7;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
    }    
}
.alert-popover.popover {
    background: #fff url('../../assets/images/header-bg.png');
    max-width: 380px;
    min-width: 320px;
}
.alert-popover-body.popover-body {
    padding: 0;
    overflow-y: auto;
    height: 380px;
}
.checkStatus {
    color: #fff !important;
    cursor: pointer;
}
.all-notifications {
    opacity: 1;
    visibility: visible;
    height: auto;
    overflow: hidden;
    .content-item-container {
        width: 100%;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        padding: 5px;
        position: relative;
        margin: 0;
        display: block;
        color: inherit;
        text-decoration: none;
        &.unread {
            background-color: #f4f4f4;
            .content-item-body .notification-message {
                font-weight: 600;
            }
        }
        .context-link {
            color: inherit;
            text-decoration: none;
        }
        .content-item-body {
            box-sizing: border-box;
            margin-bottom: 5px;
        }
        &.notification .content-item-body {
            .notification-image {
                display: inline-block;
                width: 24px;
                height: 24px;
                float: left;
                vertical-align: top;
                img {
                    height: 75%;
                }
            }
            .notification-message {
                display: inline-block;
                font-size: 12px;
                width: calc(100% - 24px);
            }
        }
        .content-item-footer {
            text-align: left;
            box-sizing: border-box;
            .timestamp {
                font-size: 10px;
                line-height: 10px;
                margin: 0;
                color: inherit;
                margin-left: 24px;
            }
        }
        .view-more {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 12px;
            line-height: 12px;
        }
    }
}
.seeAlert {
    text-align: center;
    border-top: 1px solid #ddd;
    display: grid;
}

.verticalPrimaryMenu {
    background-image: url('../../assets/images/primarymenu/verticalmenu-expand.png');
    height: 100vh;
    min-width: 72px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    text-align: center;
    transition: all .4s;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .vpm-container {
        position: relative;
        height: 100%;
    }
    .hamburger {
        font-size: 30px;
        color: #fff;
    }
    .chevrondown-par {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: fit-content;
        img {
            width: 20px;
        }
    }
    .secondary-logo {
        display: none;
        margin-top: 0.25rem;
        img {
            width: 128px;
        }
    }
    .primarymenu {
        padding: 0;
        margin: 2rem 0 1rem 0;
        list-style: none;
        text-align: center;
        li {            
            margin-bottom: 0.5rem;
            font-size: 0px;
            transition: all 0.4s;
            cursor: pointer;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                border-radius: 0.5rem;
                min-height: 50px;
                &:hover, &.active-menu {
                    background-color: #F98012;
                }
            }
        }        
    }
    .helpDeskMenu {
        text-align: center;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        padding: 10px;
        border-radius: 0.5rem;
        &:hover, &.active-menu {
            background-color: #F98012;
        }
    }
    img {
        width: 28px;
    }
    h5 {
        color: #fff;
        display: none;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: 14px;
        vertical-align: middle;
    }
    &.active {
        min-width: 260px;    
        .primarymenu {
            text-align: left;
            li a {
                justify-content: left;
            }
        }
        h5 {
            display: inline-block;
        }
        .helpDeskMenu {
            text-align: left;
        }
    }
}
.site-header, .contentarea-wrapper, .site-footer {
    padding-left: 72px;
    transition: all .4s;
    &.active {
        padding-left: 272px;
    }
}
.mb-wraper {
    padding-bottom: 75px;
    padding-left: 0;
}
