.administration-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.row1, &.row3 {
    .box {
      width: 35%;
      height: 240px;
      margin: 1%;
      transition: all 0.5s ease;
      &.middle {
        width: 24%;
        &:hover {
          width: 35%;
        }
      }
      &.middle:hover ~ &:not(.middle) {
        width: 35%;
      }
    }
  }

  &.row2 {
    .box {
      width: 24%;
      height: 240px;
      margin: 1%;
      transition: all 0.5s ease;
      &.middle {
        width: 48%;
        &:hover {
          width: 48%;
        }
      }
      &:hover {
        width: 35%;
      }
    }    
  }
    
  .default-item {    
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    position: relative;
    color: #000;
    border-radius: 30px;
    background: #D9ECFF;
    &:hover {
      color: #fff;
      background: linear-gradient(113.18deg, #1558B3 16.67%, #118CF4 94.82%);
      box-shadow: 5px 5px 11px #959595;
    }
    img {
      filter: grayscale(100%);
      max-height: 160px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    &.institute-management img {
      max-height: 150px;
    }
    &:hover img {
      filter: none;
    }
    .card-title {
      margin: 20px 30px;
      z-index: 1;
      font-weight: 600;
    }
  }  
  &.row1 .box:nth-child(even) .default-item, &.row3 .box:nth-child(even) .default-item {
    background: #A6D0F9;
    &:hover {
      background: linear-gradient(113.18deg, #1558B3 16.67%, #118CF4 94.82%);
      box-shadow: 5px 5px 11px #959595;
    }
  }
  &.row2 .box:nth-child(odd) .default-item {
    background: #A6D0F9;
    &:hover {
      background: linear-gradient(113.18deg, #1558B3 16.67%, #118CF4 94.82%);
      box-shadow: 5px 5px 11px #959595;
    }
  }
  .admin-lock {
    background: #E0E0E0;
    img {
      left: 0;
      top: 0;
      margin: auto;
    }
  }
  &.row3 .box:nth-child(even) .admin-lock, &.row3 .box:nth-child(odd) .admin-lock {
    background: #E0E0E0;
    &:hover {
      background: #E0E0E0;
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  
  .administration-box {
    max-width: 100%;
    .box {
      width: 31% !important;
    }
    .default-item {
      img {
        max-height: 140px;
      }
      &.institute-management img {
        max-height: 130px;
      }
    }
  }
}
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .administration-box {
    flex-direction: column;
    .box {
      width: 100% !important;
      height: 180px !important;
      margin: 0.65rem 0rem !important;
    }
    .default-item {
      border-radius: 20px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      .card-title {
        margin: 20px;
      }
    }
  }  
}

.bg-right{
  position: absolute;
  right: 0;
  z-index: -1;
  top: 20rem;
}
.bg-left{
  position: absolute;
  left: 0;
  z-index: -1;
  bottom: 1rem;
}