
@media only screen and (max-width: 767.98px) {
  .landing-wrapper {
    .landing-content {
      .laptopbg{
        display: none;
      }
      .laptopbg-mobile {
        margin-top: 10rem;
        display: block;
      }
      .butext{
        display: block;
        width: 100%;
        margin-top: 6rem;
      }
      .login-btn {
        top: 16%;
        left: 0;
      }
    }
    .demovideo-wrapper { 
      top: 20vh;
      right: 13vw;
      iframe {
        width: 54vw;
      }
    }
  }

  .landing-page {
    // height: auto;
    .landing-wrapper {
      justify-content: center;
    }
  }

  .landing-header  {
      padding: 6px;
      .programlist-title {
        padding: 0 14px;
        margin-bottom: 0px;
        margin-top: 22px;
      }
      .logo-wrapper img {
        height: 78px;
      }
  }

  .footer-links {
    height: auto;
    font-size: 16px;
    z-index: 2;
    .row {
        padding: 20px 0px;
        gap: 0.5rem;
      div {
        border-right: none;
      }
    }
  }

  .landing-courses {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 3rem;
    .title-wrapper {
      width: 100%;
      text-align: center;
      padding: 1rem;
      margin-bottom: 3rem;
      .explorer-btn { 
        display: none;
      }
    }
    .courseswrapper {
      flex-direction: column;
      align-items: center;
      padding: 1rem;   
      > div {
        gap: 1rem;
      }
      .allcourses-btn {
        button {
          display: block;
        }
      }
    }
  }

  .programlist-wrapper {
    height: auto;
    .logo-wrapper .login-btn Button {
      font-size: 14px;
    }
  }

  .program-catalogue {
    padding-top: 0;
    .programlist {
      padding: 0rem;
      > div {
        justify-content: center;
      }
    } 
  }

  .programlist-pagination {
    margin-bottom: 7rem;
  }

  .catalogfilter-header {
    .accordion-button:not(.collapsed){
      &::after{
        display: block;
      }
    }
  }
}
