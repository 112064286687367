// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 

}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 

}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .site-button-group {
        button {
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
        td>span>.action-icons { //action icons of table
            margin-right: 7px;
        }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    /**** Filter Start ****/
    .filter-wrapper {
        flex-wrap: wrap;
        position: relative;
    }
    .top-filter{
        flex-direction: row;
    }
    .filterdropdown {
        .filter-btn{
            position: absolute;
            top: 24px;
        }    
        .FilterProgramDropdown-wrapper{
            margin-top: 22px;
        }
    } 
        
    /**** Filter End ****/
    .mitblock {
        background-color: #ebf2fc8c;
    }
    .tabStep-indicator.sticky-top {
        position: unset;
    }
}