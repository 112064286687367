.sitepage-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    font-size: 1.64rem;
    span {
        color: #1B609D;
    }
    .btn-link.backBtn {
        background-color: #0F80EF;
        color: #fff;
        box-shadow: 3px 3px 10px 0px #A6ABBD80;
        &:hover {
            background-color: #073AD1;
        }
    }
}