.available{
    background-color: #DCE9F7 !important;
    // border: 2px solid #c1dfff !important;
}
.weekend{
  background-color: #DDD !important;
}

.holiday{
  background-color: #DDD !important;
}

.booked{
    background-color: #FEECDC !important;
    // border: 2px solid #ffcda1 !important;
}

.not_available{
  background-color: #FEECDC !important;
    // border: 2px solid #ffcda1 !important;
}

.draft-table {
    text-align: center;
    thead {
      tr {
        background-color: white;
        background-image: none;
      }
    
    }
    th{
        color: #1B609D;
        text-shadow: none;
        border: 1px solid #D3D3D3;
    }
    td{
        padding: 28px 4px;
    border: 1px solid #D3D3D3;
    }
    & .timeSlot{
        color: #1B609D;
        font-weight: 500;
      }
  }

//   table top pills
.slot-indicator{
    display: flex;
    gap: 4px;
      div{
        width: max-content;
        border-radius: 6px;
        padding: 4px 10px;
        font-size: 14px;
    }
    .available {
      border: 2px solid #c1dfff ;
    }
    .weekend{
      border: 2px solid lightgray;
  }
  .holiday{
    border: 2px solid lightgray;
}
  .booked{
      border: 2px solid #ffcda1 ;
  }
    
}

.dates-wrapper{
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    img{
      width: 26px;
    }
    b{
      font-weight: 500;
    }
  }
}

.next-previousbuttons{
  display: flex;
  gap: 8px;
  justify-content: center;
  button{
    background: white;
    color: black;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #F6F6F6;
    box-shadow: 3px 3px 7px 0px rgba(166, 171, 189, 0.40), -5px -5px 10px 0px #FAFBFF;
  }
}
.draft-table-wrapper{
  border-radius: 16px;
  border: 1px solid #D3D3D3;
}