.assignRoomFilter {
    .searchWrapper {
        border: none;
        min-height: initial;
        padding: 0;
        border-radius: unset;
        .searchBox  {
            margin-top: 0;
        }
        .multiSelectContainer {
            margin-bottom: 0 !important;
        }
    }
}
.roomallocation {
    .roomBox {
        background-color: #fff;
        box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
        padding: 1rem 2rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;        
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 10px;
            background: url("../../assets/images/hostel/door.png") no-repeat bottom left;
            background-size: 100%;
            width: 40px;
            height: 40px;
        }
    }
    .roomBoxContainer {
        display: flex;
        gap: 3rem;
        flex-wrap: wrap;
        margin-bottom: 2rem;
    }
    .beds {
        display: flex;
        gap: 1rem;
        .bed {
            width: 82px;
            height: 126px;
            padding-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("../../assets/images/hostel/available.png") no-repeat center;
            font-weight: 600;
            &.occupiedbed {
                background: url("../../assets/images/hostel/booked.png") no-repeat center;
            }
            &.selectedbed {
                background: url("../../assets/images/hostel/selected.png") no-repeat center;
            }
        }
    }
    .roomnumber {
        font-weight: 600;
    }
    .selectedbedDetails {
        background-color: #FAFAFA;
        box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.26);
        padding: 2rem;
        border-radius: 10px;
        .title span {
            color: #145A95;
        }
        .group-btn {
            display: flex;
            justify-content: center;
            gap: 4rem;
            margin-top: 3rem;
        }
        .badgesLable {
            display: flex;
            justify-content: space-around;
            margin: 3rem 0 0 0;
            padding: 0;
            list-style: none;
            span {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #fff;
                vertical-align: text-bottom;
                border-radius: 6px;
            }
            .selectedLable span {
                background-color: #5D9DDC;
                color: #5D9DDC;
            }
            .occupiedLable span {
                background-color: #E46C6C;
                color: #E46C6C;
            }
            .availableLable span {
                background-color: #A7DAA6;
                color: #A7DAA6;
            }
        }
    }
}
.hostelAdmin {
    .haNotification {
        border: 1px solid #EFEFEF;
        border-radius: 10px;
        box-shadow: 0px 5px 7px 0px rgba(23, 23, 37, 0.09);
        padding: 1.5rem;
        background-color: #fff;
        .accordion-button {
            background-color: transparent;
            font-size: 18px;
            &::after {
                display: none;
            }
            &:not(.collapsed) {
                box-shadow: none;
            }
            &::before {
                content: "";
                background-color: #135891;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 1rem;
            }
        }
        .accordion-item {
            border: none;
            margin-top: 1rem;
            border-radius: 8px;
            background-color: #FAFAFA;
            box-shadow: 0px 6px 12px 0px rgba(52, 30, 114, 0.12);
        }
        .accordion-body {
            font-size: 13px;
            padding-top: 0.25rem;
        }
        .haUserInfo {
            font-size: 12px;
            color: #000;
            margin-bottom: 0.5rem;
            span {
                color: #888;
            }
        }
    }
    
    .haBox {
        background-color: #108AF4;
        box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.13);
        padding: 1rem;
        margin: 0.75rem;
        border-radius: 1rem;
        display: flex;
        min-height: 220px;
        position: relative;
        cursor: pointer;
        &.colorBox1 {
            background-color: #125589;
        }
        &.colorBox2 {
            background-color: #A6D0F9;
            h3 {
                color: #125589;
            }
        }
        &.colorBox3 {
            background-color: #073AD1;
        }
        &.colorBox4 {
            background-color: #0B5DE0;
        }
        .counts {
            position: absolute;
            right: -10px;
            top: -10px;
            background-color: #FC0000;
            border-radius: 50%;
            padding: 0.25rem;
            width: 32px;
            height: 32px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.static {
                background-color: #00C206;
            }
        }
        h3 {
            font-size: 22px;
            color: #fff;
            margin-bottom: 0;
            margin-top: 0.25rem;
        }
        .thumbIcons {
            height: 134px;
            position: absolute;
            bottom: 0;
            right: 1rem;
            &.bigsize {
                height: 148px;
            }
            &.invoicePayment {
               height: 116px; 
            }
        }
        &.bedbox {
            background-color: #F98012;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            .thumbIcons {
                position: initial;
                margin-bottom: 1rem;
                height: 140px;
            }
            h4 {
                color: #fff;
            }
            .thumbDetails {
                text-align: center;
                font-weight: 500;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        .progressInfo {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 3rem;
            .progresstitle {
                margin-bottom: 1.5rem;
                .progress {
                    height: 0.6rem;
                }
            }
        }
    }
    .progress-bar {
        background: linear-gradient(269.92deg, #393939 1.69%, #393939 99.93%);
    }
    .myhaBox {
        background-color: #F98012;
        box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.13);
        padding: 1rem;
        margin: 0.75rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        min-height: 220px;
        color: #fff;
        
        .myRoomTitle {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            h3 {
                font-size: 22px;
                margin-bottom: 0;
                margin-right: 3.25rem;
            }
            > div {
                display: flex;
                gap: 1rem;
                .btn {
                    background-color: #fff;
                    border-radius: 0.5rem;
                    padding: 4px 5px;
                    box-shadow: 0px 2px 2px 0px #00000040;
                    &:hover {
                        background-color: #f5f5f5;
                    }
                    img {
                        width: 24px;
                    }
                }
            }
        }
        
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                padding: 0.25rem 0.5rem 0.25rem 1.75rem;
                background-size: 16px;
                background-repeat: no-repeat;
                background-position: left;
                font-size: 13px;
            }
            b {
                font-weight: 500;
                display: inline-block;
                width: 120px;
                position: relative;
                margin-right: 2.5rem;
                &::after {
                    content: ":";
                    position: absolute;
                    right: 0;
                }
            }
            .mhName {
                background-image: url('../../assets/images/hostel/hostel-small.png');                
            }
            .mhRoomNo {
                background-image: url('../../assets/images/hostel/room-small.png');                
            }
            .mhRoomType {
                background-image: url('../../assets/images/hostel/roomtype-ac-small.png');                
            }
            .mhRoomType-nonAc {
                background-image: url('../../assets/images/hostel/roomtype-nonac-small.png');                
            }
            .mhValid {
                background-image: url('../../assets/images/hostel/valid-small.png');                
            }
        }
        .mhMyRoomImage {
            position: relative;
            margin-left: auto;
            img {
                max-height: 200px;
                margin-bottom: -1.25rem;
            }
        }
        .mhMyFacilities {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.75rem;
            position: relative;
            padding-left:1rem;
            margin-left: 0.75rem;
            min-height: 100px;
            &::before {
                content: "";
                width: 2px;
                height: 60px;
                background-color: #fff;
                position: absolute;
                left: 0;
            }
            img {
                width: 18px;
            }
        }
    }

}
.bookingRequestDetails {
    > p {
        margin-bottom: 0.75rem;
    }
}
.beds-number, .invoice-number {
    .action-icons {
        margin-right: 0.5rem;
        margin-bottom: 0.25rem;
        font-size: 14px;
    }
}
.feeApplicable {
    .input-group {
        flex-wrap: initial;
    }
}
.roomPixVideo {
    position: relative;
    margin-right: 1rem;
    .videoFile {
        width: 72px;
        height: 72px;
        background-color: #000;
        border-radius: 0.5rem;
        video {
            width: 72px;
            height: 72px;
        }
        .playButton {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .imageFile {
        width: 72px;
        height: 72px;
        border-radius: 0.5rem;
    }    
}
.messmenuTable {
    th:last-child {
        width: initial;
    }
    th:first-child {
        width: 12%;
    }
    tr td:first-child {
        font-weight: 600;
    }
}
.facilityIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F98012;
    border-radius: 50%;
    padding: 0.5rem;
    width: 40px;
    height: 40px;        
    img {
        width: 18px;
    }
}
.hmUplodedImage {
    position: relative;
    background-color: #F98012;
    display: flex;
    width: 50px;
    padding: 0.5rem;
    justify-content: center;
    border-radius: 1rem;
}
.closeFile {
    width: 24px;
    position: absolute;
    right: -10px;
    bottom: -10px;
    cursor: pointer;
    padding: 6px;
    border-radius: 50%;
    background-color: #fff;
}

.colon-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li  {
        padding: 0.15rem 0;
        display: flex;
        word-wrap: break-word;
        word-break: break-word;
        b {
            font-weight: 500;
            display: inline-block;
            width: 140px;
            position: relative;
            margin-right: 10%;
            &::after {
                content: ":";
                position: absolute;
                right: 0;
            }
        }
    }
    .bluColor {
        color: #145A95;
    }
}
.chargeSummary {
    background-color: #FAFAFA;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.26);
    ul {
        margin: 1rem;
        li {
            color: #145A95;
            b {
                width: 65%;
            }
            &:first-child {
                margin-bottom: 1rem;
                color: #212529;
            }
            &:last-child {
                margin-top: 1rem;
                color: #212529;
            }
        }
    }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
    .chargeSummary {
        ul {
            li {
                b {
                    width: 60%;
                }
            }
        }
    }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .hostelAdmin {
        .haNotification {
            margin-top: 1rem;
        }
        .haBox {
            .thumbIcons {
                height: 114px;
                &.invoicePayment {
                    height: 106px;
                }
            }
        }
        .myhaBox {
            ul {
                b {
                    margin-right: 1.5rem;
                    width: 100px;
                }
            }
            .mhMyRoomImage {
                img {
                    max-height: 180px;
                    margin-bottom: -2.5rem;
                }
            }
        }
    }
    .chargeSummary {
        ul {
            li {
                b {
                    width: 50%;
                }
            }
        }
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .hostelAdmin {
        .myhaBox {           
            .mhMyRoomImage {
                img {
                    max-height: 160px;
                    margin-bottom: -3.5rem;
                }
            }
        }
    }
    .chargeSummary {
        ul {
            li {
                b {
                    width: 50%;
                }
            }
        }
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .hostelAdmin {
        .haBox {
            .thumbIcons {
                height: 134px;
                &.invoicePayment {
                    height: 116px;
                }
            }
        }
    }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .myRoom {
        .myhaBox {
            flex-direction: column;
            align-items: flex-start;
            .mhMyRoomImage {
                margin-left: initial;
                width: 100%;
                text-align: center;
                img {
                    max-height: 180px;
                    margin-bottom: 0;
                }
            }
        }
    }
    .hostelAdmin {
        .haBox {
            .thumbIcons {
                height: 164px;
                &.invoicePayment {
                    height: 136px;
                }
            }
        }
        .myhaBox {
            .mhMyFacilities {
                margin: 1rem auto 0 auto;
                padding: 0.75rem 1rem 0 1rem;
                flex-direction: row;
                align-items: center;
                min-height: initial;
                border-top: 2px solid #fff;
                &::before {
                    display: none;
                }
            }
        }
    }
    .colon-list {
        li {
            b {
                width: 120px;
            }
        }        
    }
    .chargeSummary {
        padding: 1rem;
        ul {
            margin: 1rem 0.5rem;
        }
    }
}